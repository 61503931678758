
//***********************************************************************
//** JS RDTS-FORM-VALIDATOR
//** Publisher: rdts AG
//** Author: Daniel Franzen
//** Version: 1.0
//** SuperClass RDTS
//** Requires: jquery
//***********************************************************************

var undefined;
var jq = jQuery;
// **********************************************************************
// **********************************************************************
// ** SuperClass RDTS
// **********************************************************************


var RDTS = {
};

// **********************************************************************

RDTS.error = function (message) {
    throw new Error(message);
};

// **********************************************************************

RDTS.log = function (level, message) {
    if (window.console) {
        if (window.console[level]) {
            window.console[level](message);
        } else {
            window.console.log(level + ': ' + message);
        }
    } else {
        //alert(level + ': ' + message);
    }
};

// **********************************************************************

RDTS.random = function (max, min) {
    if (!jq.isNumeric(max)) {
        max = 1;
    }
    if (!jq.isNumeric(min)) {
        min = 1;
    }
    var n = Math.floor((Math.random() * max) + 1);
    if (n < min) {
        n = min;
    }
    return n;
};

// **********************************************************************

RDTS.toNumber = function (str) {
    var n = str.toString().replace(/\./gi, '');
    n = n.replace(/\,/gi, '.');
    n = parseFloat(n);
    return n;
};

// **********************************************************************

RDTS.is_number = function (n) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (jq.isNumeric(n)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.is_function = function (obj) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (jq.isFunction(obj)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.is_string = function (str) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (RDTS.type(str) == 'string') {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.is_email = function (str) {
    var mail = RDTS.trim(str);
    var regEx = new RegExp('^([a-zA-Z0-9\\-\\.\\_]+)(\\@)([a-zA-Z0-9\\-\\.]+)(\\.)([a-zA-Z]{2,4})$');
    if (!mail.match(regEx)) {
        return false;
    }
    return true;
};

// **********************************************************************

RDTS.is_integer = function (str) {
    var integer = RDTS.trim(str);
    var regEx = new RegExp('^[0-9]+$');
    if (!integer.match(regEx)) {
        return false;
    }
    return true;
};

// **********************************************************************

RDTS.validateTime = function (str) {
    if (str && str.match(/(\d\d\:\d\d(\:\d\d)?)/)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.validateDate = function (str) {
    if (str && str.match(/(\d\d)\.(\d\d)\.(\d\d\d\d)/)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.validatePassword = function (str) {
    if (str && str.match(/([a-z])/) && str.match(/([0-9])/) && str.match(/([A-Z])/)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.validateZip = function (elem) {
    var input = jq(elem);
    var file = input[0].files[0];
    var id = input.attr('id');
    var name = file.name;
    var size = file.length;
    input.parent().find('.text-error').remove();
    jq('label[for="' + id + '"] span').text(name);
    if (name && name.match(/^[^.]+\.{0,1}(zip)$/)) {
        if(size && size < 8000000){
            return true;
        } else {
            jq('<div class="text-error">Bitte max 8MB</div>').insertAfter('label[for="' + id + '"]');
            return false;
        }
        
    } else {
        jq('<div class="text-error">Bitte nur .zip Dateien</div>').insertAfter('label[for="' + id + '"]');
        return false;
    }
    return false;
};

// **********************************************************************

RDTS.isSYSDate = function (str) {
    if (str && str.match(/(\d\d\d\d)\-(\d\d)\-(\d\d)/)) {
        return true;
    }
    return false;
};

// **********************************************************************

RDTS.type = function (x) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    return jq.type(x);
};

// **********************************************************************

RDTS.round = function (n, floatpoint) {
    if (floatpoint == undefined) {
        floatpoint = 0;
    }
    n = parseFloat(n);
    var _fp1 = Math.pow(10, (floatpoint + 1));
    n = Math.round(n * _fp1) / _fp1;
    var _fp2 = Math.pow(10, floatpoint);
    n = Math.round(n * _fp2) / _fp2;
    return n;
};

// **********************************************************************

RDTS.formatCash = function (n) {
    var result = RDTS.round(n, 2);
    result = result.toString();
    if (!result.match(/\./)) {
        result = result + '.00';
    }
    if (result.split(/\./)[1].length == 1) {
        result = result + '0';
    }
    result = result.replace(/\./, ',');
    return result;
};

// **********************************************************************

RDTS.formatNumber = function (n) {
    var result = n;
    result = result.toString();
    result = result.replace(/\./, ',');
    return result;
};

// **********************************************************************

RDTS.trim = function (str) {
    return jq.trim(str);
};

// **********************************************************************

RDTS.formatTime = function (str) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (str == undefined) {
        return str;
    }
    if (!RDTS.is_string(str)) {
        RDTS.log('error', 'Incorrect type');
    }
    if (!str.length) {
        return str;
    }
    var reg = /(\d\d)\:(\d\d)\:(\d\d)/;
    var res = reg.exec(str);
    if (res) {
        return res[1] + ':' + res[2];
    }
    return str;
};

// **********************************************************************

RDTS.formatTimeSYS = function (str) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (str == undefined) {
        return str;
    }
    if (!RDTS.is_string(str)) {
        RDTS.log('error', 'Incorrect type');
    }
    if (!str.length) {
        return str;
    }
    var reg = /(\d\d)\:(\d\d)\:?(\d\d)?/;
    var res = reg.exec(str);
    if (res) {
        return res[1] + ':' + res[2] + ':' + ((res[3]) ? res[3] : '00');
    }
    return str;
};

// **********************************************************************

RDTS.formatDate = function (str) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (str == undefined) {
        return str;
    }
    if (!RDTS.is_string(str)) {
        RDTS.log('error', 'Incorrect type');
    }
    if (!str.length) {
        return str;
    }
    if (str.match(/(\d\d)\.(\d\d)\.(\d\d\d\d)/)) {
        return str;
    }
    var reg = /(\d\d\d\d)\-(\d\d)\-(\d\d)/;
    var res = reg.exec(str);
    if (res) {
        return res[3] + '.' + res[2] + '.' + res[1];
    }
    return str;
};

// **********************************************************************

RDTS.formatDateSYS = function (str) {
    if (arguments.length != 1) {
        RDTS.log('error', 'Incorrect arguments length');
    }
    if (str == undefined) {
        return str;
    }
    if (!RDTS.is_string(str)) {
        RDTS.log('error', 'Incorrect type');
    }
    if (!str.length) {
        return str;
    }
    if (str.match(/(\d\d\d\d)\-(\d\d)\-(\d\d)/)) {
        return str;
    }
    var reg = /(\d\d)\.(\d\d)\.(\d\d\d\d)/;
    var res = reg.exec(str);
    if (res) {
        return res[3] + '-' + res[2] + '-' + res[1];
    }
    return str;
};

// **********************************************************************

RDTS.encodeXML = function (str) {
    if (!str) {
        return str;
    }
    str = str.toString();
    str = str.replace(/\&/, '&amp;');
    str = str.replace(/\</, '&lt;');
    str = str.replace(/\>/, '&gt;');
    str = str.replace(/\"/, '&quot;');
    str = str.replace(/\'/, '&apos;');
    return str;
};

// **********************************************************************
// **********************************************************************
// ** SubClass RDTS.Form
// **********************************************************************

RDTS.Form = {
};

// **********************************************************************

RDTS.Form.init = function (elem) {
    var forms = jq('form');
    if (elem) {
        forms = jq(elem);
    }
    forms.each(function () {
        var form = jq(this);
        if (form.attr('data-type') == 'xxx') {
            form.on('submit.validate', function (e) {
                return RDTS.Form.submitXXX(e, this);
            });
        } else {
            form.on('submit.validate', function (e) {
                return RDTS.Form.submit(e, this);
            });
        }
        form.find('input[type="text"],input[type="password"],input[type="email"],input[type="hidden"],textarea').on('input.validate', function (e) {
            RDTS.Form.validateInput(this);
        });
        form.find('select').on('change.validate', function (e) {
            RDTS.Form.validateInput(this);
        });
        form.find('input[type="checkbox"]').on('click.validate', function (e) {
            RDTS.Form.validateInput(this);
        });
        if (form.attr('data-validateonready') == 'yes') {
            RDTS.Form.isValid(undefined, form);
        }
    });
};

// **********************************************************************

RDTS.Form.submitXXX = function (e, elem) {
    var form = jq(elem);
    if (!RDTS.Form.isValid(e, form)) {
        return false;
    }
    alert('Alternative Submitfunktion');
    return true;
};

// **********************************************************************

RDTS.Form.submit = function (e, elem) {
    var form = jq(elem);
    if (!RDTS.Form.isValid(e, form)) {
        return false;
    }
    return true;
};

// **********************************************************************

RDTS.Form.isValid = function (e,elem) {
    var form = jq(elem);
    var isvalid = true;
    form.find('input,textarea,select').each(function () {
        var input = jq(this);
        if (!RDTS.Form.validateInput(input)) {
            isvalid = false;
        }
    });
    return isvalid;
};

// **********************************************************************

RDTS.Form.validateInput = function (elem, selfonly) {
    var input = jq(elem);
    var form = input.parents('form').first();
    var isvalid = true;
    var dependFieldOn;
    var dependFieldOr;
    var dependFieldComp;
    if (input.attr('data-requiredon') && input.attr('data-requiredon') != '') {
        dependFieldOn = form.find('*[id="' + input.attr('data-requiredon') + '"]');
        if (dependFieldOn.length && RDTS.Form._inputHasValue(dependFieldOn, form)) {
            if (!RDTS.Form._inputHasValue(input, form)) {
                isvalid = false;
            }
        }
    }
    if (input.attr('data-requiredor') && input.attr('data-requiredor') != '') {
        dependFieldOr = form.find('*[data-requiredor="' + input.attr('data-requiredor') + '"]').not(input);
        var _hasInputOr = false;
        dependFieldOr.each(function () {
            var dO = jq(this);
            if (RDTS.Form._inputHasValue(dO, form)) {
                _hasInputOr = true;
            }
        });
        if (!_hasInputOr) {
            if (!RDTS.Form._inputHasValue(input, form)) {
                isvalid = false;
            }
        }
    }
    if (input.attr('data-required') == 'yes') {
        if (!RDTS.Form._inputHasValue(input, form)) {
            isvalid = false;
        }
    }
    if (input.attr('data-compareto') && input.attr('data-compareto') != '') {
        dependFieldComp = form.find('*[id="' + input.attr('data-compareto') + '"]');
        if (dependFieldComp.length && (RDTS.Form._inputHasValue(dependFieldComp, form))) {
            var val1 = RDTS.trim(input.val());
            var val2 = RDTS.trim(dependFieldComp.val());
            if (val1 != val2) {
                isvalid = false;
            }
        }
    }
    if (!RDTS.Form.validateInputDataType(input)) {
        isvalid = false;
    }
    if (!RDTS.Form.validateInputMinLength(input)) {
        isvalid = false;
    }
    if (!RDTS.Form.validateInputMaxLength(input)) {
        isvalid = false;
    }

    if (!isvalid) {
        input.parents('div.input').first().attr('data-valid', 'no').addClass('has-error');
    } else {
        input.parents('div.input').first().attr('data-valid', 'yes').removeClass('has-error');
    }
    if (selfonly != 1) {
        var _compareto = RDTS.Form._getFieldCompareTo(form, input);
        if (_compareto.length) {
            RDTS.Form.validateInput(_compareto, 1);
        }
        var _requiredon = RDTS.Form._getFieldRequiredOn(form, input);
        if (_requiredon.length) {
            _requiredon.each(function () {
                var rO = jq(this);
                RDTS.Form.validateInput(rO, 1);
            });
        }
        if (dependFieldOn) {
            RDTS.Form.validateInput(dependFieldOn, 1);
        }
        if (dependFieldOr) {
            dependFieldOr.each(function () {
                var dO = jq(this);
                RDTS.Form.validateInput(dO, 1);
            });
        }
        if (dependFieldComp) {
            RDTS.Form.validateInput(dependFieldComp, 1);
        }
    }
    return isvalid;
};

// **********************************************************************

RDTS.Form.validateInputMaxLength = function (elem) {
    var input = jq(elem);
    var maxlength = input.attr('data-maxlength');
    var value = RDTS.trim(input.val());
    var isvalid = true;
    if (value.length && maxlength) {
        if (value.length > parseInt(maxlength)) {
            isvalid = false;
        }
    }
    return isvalid;
};

// **********************************************************************

RDTS.Form._inputHasValue = function (elem, form) {
    var input = jq(elem);
    var hasval = true;
    if (input.attr('type') == 'radio' || input.attr('type') == 'checkbox') {
        var name = input.attr('name');
        var boxes = form.find('input[name="' + name + '"]');
        if (!boxes.filter(':checked').length) {
            hasval = false;
        }
    } else {
        var val = RDTS.trim(input.val());
        if (!val.length) {
            hasval = false;
        }
    }
    return hasval;
};

// **********************************************************************

RDTS.Form._getFieldRequiredOn = function (form, elem) {
    var input = jq(elem);
    var id = input.attr('id');
    return form.find('*[data-requiredon="' + id + '"]').not(input);
};

// **********************************************************************

RDTS.Form._getFieldCompareTo = function (form, elem) {
    var input = jq(elem);
    var id = input.attr('id');
    var items = form.find('*[data-compareto="' + id + '"]').not(input);
    return items;
};

// **********************************************************************

RDTS.Form.validateInputMinLength = function (elem) {
    var input = jq(elem);
    var minlength = input.attr('data-minlength');
    var value = RDTS.trim(input.val());
    var isvalid = true;
    if (value.length && minlength) {
        if (value.length < parseInt(minlength)) {
            isvalid = false;
        }
    }
    return isvalid;
};

// **********************************************************************

RDTS.Form.validateInputDataType = function (elem) {
    var input = jq(elem);
    var datatype = input.attr('data-datatype');
    var value = RDTS.trim(input.val());
    var isvalid = true;
    if (value.length) {
        if (datatype == 'email') {
            isvalid = RDTS.is_email(value);
        }
        if (datatype == 'integer') {
            isvalid = RDTS.is_integer(value);
        }
        if (datatype == 'number') {
            isvalid = RDTS.is_number(RDTS.toNumber(value));
        }
        if (datatype == 'date') {
            isvalid = RDTS.validateDate(value);
        }
        if (datatype == 'password') {
            isvalid = RDTS.validatePassword(value);
        }
        if (datatype == 'zip') {
            isvalid = RDTS.validateZip(input);
        }
    }
    return isvalid;
};

// **********************************************************************

jq(document).ready(function () {
    RDTS.Form.init();
});
